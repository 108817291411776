import React from 'react';
import ReactDOM from 'react-dom/client';
import Routes from './Routes';
import '../src/css/global.css';
import '../src/css/splashintro.css';
import '../src/css/root.css';
import '../src/css/introduction.css';
import '../src/css/contact.css';
import '../src/css/mapadavirada.css';


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Routes />
  </React.StrictMode>
);
