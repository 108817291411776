import React from "react";
import { useMediaQuery } from 'react-responsive';
import Home from './Pages/Home';
import LandscapingMode from './Components/landscapingMode';

const App = () => {

    const isLandscape = useMediaQuery({ query: '(orientation: landscape)' });

    return (
        <div>
            {isLandscape ? (
                <Home />
            ) : (
                <LandscapingMode />
            )}
        </div>
    );
};

export default App;