import React from "react";

const Footer = () => {

    return (
        <div className="footer">
            <h5>2024 Pêndulo Co. | Todos os direitos reservados.</h5>
        </div>
    )
}

export default Footer;