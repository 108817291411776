import React, { useState } from "react";
import { Emojione } from 'react-emoji-render';
import logo from '../img/pendulologo.png';
import banner from '../img/mapadaviradabook.png';


const MapadaVirada = () => {
    const [step, setUseStep] = useState(1);

    const ask1 = () => {
        return (
            <>
                <div className="steps-count">
                    <span className="actuallystep">1</span>
                    <span className="misssetp">2</span>
                    <span className="misssetp">3</span>
                    <span className="misssetp">4</span>
                    <span className="misssetp">5</span>
                    <span className="misssetp">6</span>
                </div>
                <div className="mpvd-ask">
                    <div>
                        <h1>Você paga alguma conta?</h1>
                    </div>
                </div>
                <div className="mpvd-container">
                    <div className="choices-containers">
                        <div onClick={() => setUseStep(2)}>
                            <span><Emojione text=":thumbsup:" /></span>
                            <h3>Sim</h3>
                        </div>
                        <div onClick={() => setUseStep(2)}>
                            <span><Emojione text=":thumbsdown:" /></span>
                            <h3>Não</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ask2 = () => {
        return (
            <>
                <div className="steps-count">
                    <span className="actuallystep">1</span>
                    <span className="actuallystep">2</span>
                    <span className="misssetp">3</span>
                    <span className="misssetp">4</span>
                    <span className="misssetp">5</span>
                    <span className="misssetp">6</span>
                </div>
                <div className="mpvd-ask">
                    <div>
                        <h1>Está buscando mais controle sobre suas finanças?</h1>
                    </div>
                </div>
                <div className="mpvd-container">
                    <div className="choices-containers">
                        <div onClick={() => setUseStep(3)}>
                            <span><Emojione text="🤑" /></span>
                            <h3>Sim</h3>
                        </div>
                        <div onClick={() => setUseStep(3)}>
                            <span><Emojione text="🫣" /></span>
                            <h3>Não</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ask3 = () => {
        return (
            <>
                <div className="steps-count">
                    <span className="actuallystep">1</span>
                    <span className="actuallystep">2</span>
                    <span className="actuallystep">3</span>
                    <span className="misssetp">4</span>
                    <span className="misssetp">5</span>
                    <span className="misssetp">6</span>
                </div>
                <div className="mpvd-ask">
                    <div>
                        <h1>Você gostaria de fazer seu dinheiro render mais?</h1>
                    </div>
                </div>
                <div className="mpvd-container">
                    <div className="choices-containers">
                        <div onClick={() => setUseStep(4)}>
                            <span><Emojione text="✅" /></span>
                            <h3>Com certeza!</h3>
                        </div>
                        <div onClick={() => setUseStep(4)}>
                            <span><Emojione text="❌" /></span>
                            <h3>Por enquanto não</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ask4 = () => {
        return (
            <>
                <div className="steps-count">
                    <span className="actuallystep">1</span>
                    <span className="actuallystep">2</span>
                    <span className="actuallystep">3</span>
                    <span className="actuallystep">4</span>
                    <span className="misssetp">5</span>
                    <span className="misssetp">6</span>
                </div>
                <div className="mpvd-ask">
                    <div>
                        <h1>Gostaria de ganhar benefícios enquanto realiza pagamentos?</h1>
                    </div>
                </div>
                <div className="mpvd-container">
                    <div className="choices-containers">
                        <div onClick={() => setUseStep(5)}>
                            <span><Emojione text="🤑" /></span>
                            <h3>Claro!</h3>
                        </div>
                        <div onClick={() => setUseStep(5)}>
                            <span><Emojione text="🤔" /></span>
                            <h3>Não sei</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const ask5 = () => {
        return (
            <>
                <div className="steps-count">
                    <span className="actuallystep">1</span>
                    <span className="actuallystep">2</span>
                    <span className="actuallystep">3</span>
                    <span className="actuallystep">4</span>
                    <span className="actuallystep">5</span>
                    <span className="misssetp">6</span>
                </div>
                <div className="mpvd-ask">
                    <div>
                        <h1>Tem interesse em aprender a acumular milhas e cashback?</h1>
                    </div>
                </div>
                <div className="mpvd-container">
                    <div className="choices-containers">
                        <div onClick={() => setUseStep(6)}>
                            <span><Emojione text="🫡" /></span>
                            <h3>Agoraa!</h3>
                        </div>
                        <div onClick={() => setUseStep(6)}>
                            <span><Emojione text=":thumbsdown:" /></span>
                            <h3>No momento não</h3>
                        </div>
                    </div>
                </div>
            </>
        )
    }

    const finish = () => {
        return (
            <div className="checkout-1">

                <div>
                    <h2>Com base nas suas respostas, ficou claro que o Mapa da Virada é exatamente o que você precisa para transformar seus gastos em ganhos! Por apenas R$ 28,97, você agora saberá como gastar de forma lucrativa! 🤑</h2>
                </div>

                <img src={banner} alt="banner" width={320} />

                <div>
                    <h2>Clique no link, baixe agora mesmo o ebook e mude já sua vida financeira! ⤵️</h2>
                </div>

                <div className="mpvd-btn-buy">
                    <a href="https://bit.ly/omapadavirada" target="_blank" rel="noreferrer"><h1>Baixar Ebook!</h1></a>
                </div>
            </div>
        )
    }

    return (
        <section className="mpvd-virada">

            {step === 1 ? (
                ask1()
            ) : step === 2 ? (
                ask2()
            ) : step === 3 ? (
                ask3()
            ) : step === 4 ? (
                ask4()
            ) : step === 5 ? (
                ask5()
            ) : step === 6 ? (
                finish()
            ) : null}

            <div className="mpvd-credits">
                <h2>Mapa da Virada</h2><span>By</span><img src={logo} alt="logo" width={30} />
            </div>
        </section>
    )
}

export default MapadaVirada;