import React, { useEffect } from 'react';
import * as THREE from 'three';
import penduloNeonName from '../img/SPlashPendulo.png';
import smokeelement from '../img/Smoke-Element.png';

const IntroSmokeEffect = () => {
  useEffect(() => {
    let camera, scene, renderer;
    let geometry, material, mesh;
    let clock, cubeSineDriver, textGeo, textTexture, textMaterial, text;
    let light, smokeTexture, smokeMaterial, smokeGeo, smokeParticles = [];

    function init() {
      clock = new THREE.Clock();

      renderer = new THREE.WebGLRenderer();
      renderer.setSize(window.innerWidth, window.innerHeight);

      scene = new THREE.Scene();

      camera = new THREE.PerspectiveCamera(75, window.innerWidth / window.innerHeight, 1, 10000);
      camera.position.z = 1000;
      scene.add(camera);

      geometry = new THREE.BoxGeometry(200, 200, 200);
      material = new THREE.MeshLambertMaterial({ color: 0xaa6666, wireframe: false });
      mesh = new THREE.Mesh(geometry, material);
      cubeSineDriver = 0;

      textGeo = new THREE.PlaneGeometry(300, 300);
      THREE.ImageUtils.crossOrigin = '';
      textTexture = new THREE.TextureLoader().load(penduloNeonName);
      textMaterial = new THREE.MeshLambertMaterial({
        color: 0x00ffff,
        opacity: 1,
        map: textTexture,
        transparent: true,
        blending: THREE.AdditiveBlending,
      });
      text = new THREE.Mesh(textGeo, textMaterial);
      text.position.z = 800;
      scene.add(text);

      light = new THREE.DirectionalLight(0xffffff, 0.5);
      light.position.set(-1, 0, 1);
      scene.add(light);

      smokeTexture = new THREE.TextureLoader().load(smokeelement);
      smokeMaterial = new THREE.MeshLambertMaterial({
        color: 0x073a58,
        map: smokeTexture,
        transparent: true,
      });
      smokeGeo = new THREE.PlaneGeometry(300, 300);
      smokeParticles = [];

      for (let p = 0; p < 150; p++) {
        const particle = new THREE.Mesh(smokeGeo, smokeMaterial);
        particle.position.set(Math.random() * 500 - 250, Math.random() * 500 - 250, Math.random() * 1000 - 100);
        particle.rotation.z = Math.random() * 360;
        scene.add(particle);
        smokeParticles.push(particle);
      }

      document.getElementById('threejs-container').appendChild(renderer.domElement);
    }

    function animate() {
      const delta = clock.getDelta();
      requestAnimationFrame(animate);
      evolveSmoke(delta);
      render();
    }

    function evolveSmoke(delta) {
      let sp = smokeParticles.length;
      while (sp--) {
        smokeParticles[sp].rotation.z += delta * 0.2;
      }
    }

    function render() {
      mesh.rotation.x += 0.005;
      mesh.rotation.y += 0.01;
      cubeSineDriver += 0.01;
      mesh.position.z = 100 + Math.sin(cubeSineDriver) * 500;
      renderer.render(scene, camera);
    }

    init();
    animate();

  }, []);
  
  return <div id="threejs-container" />;
};

export default IntroSmokeEffect;
