import React from "react";
import rocketImg from '../img/rocket.png'

const Introduction = () => {

    return (
        <section>
            <div className="introResume">
                <h1>ALCANÇANDO O INALCANÇÁVEL!</h1>

                <p>Construa o futuro digital do seu negócio através da inovação que <span className="orangeMarker">impulsiona</span>! Descubra como nossas soluções de software feitas sob medida podem <span className="orangeMarker">transformar</span> seu negócio, preparando-o para o <span className="orangeMarker">sucesso</span> digital. Nossa expertise em tecnologia e nosso compromisso com a excelência garantem que cada solução seja adaptada às suas necessidades específicas, proporcionando eficiência operacional e <span className="orangeMarker">vantagem</span> competitiva.</p>
            </div>


            <div className="rocketImg">
                <img src={rocketImg} alt="Rocket" />
            </div>


        </section>
    )
}

export default Introduction;