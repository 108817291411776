import React from "react";
import { FaInstagram, FaWhatsapp  } from "react-icons/fa";
import { IoMailOutline } from "react-icons/io5";

const Contact = () => {

    return (
        <section>
            <div className="bg-boxes">
                <div className="blur-container">
                    <h1>ATENÇÃO!</h1>
                    <h2>Clique em algum dos ícones apenas se você deseja elevar o nível do seu negócio ou marca.</h2>
                    <div className="social-media-icons">
                        <div>
                            <a href="https://www.instagram.com/co.pendulo" target="_noblank"><span className="sm-ico"><FaInstagram /></span></a>
                        </div>
                        <div>
                        <a href="https://api.whatsapp.com/send?phone=5577988190015&text=Ol%C3%A1!%20Vim%20da%20P%C3%A1gina%20Web." target="_noblank"><span className="sm-ico"><FaWhatsapp /></span></a>
                        </div>
                        <div>
                        <a href="mailto:contato@pendulocorp.com.br" target="_noblank"><span className="sm-ico"><IoMailOutline /></span></a>
                        </div>
                    </div>

                </div>

            </div>
            
        </section>
    )
}

export default Contact