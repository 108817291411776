import React from "react";
import SplashIntro from "../Sections/SplashIntro";
import Introduction from "../Sections/Introduction";
import Contact from "../Sections/contact";
import Footer from "../Components/footer";

const Home = () => {

    return (
        <div className="landingPage-scroll">
            <SplashIntro />
            <Introduction />
            <Contact />
            <Footer />
        </div>
    )
}

export default Home;