import React from "react";
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import App from "./App";
import MapadaVirada from "./Pages/Mapadavirada";

export default () => {

    return (

        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App />} />
                <Route path="/mapadavirada" element={<MapadaVirada />} />
            </Routes>
        </BrowserRouter>

    );
}