import React from "react";
import IntroSmokeEffect from "../Components/introSmoke";
import Lottie from "react-lottie";
import swipeAnimationData from '../animations/swipedown.json';

const SplashIntro = () => {

    const swipeAnimationDataConfig = {
        loop: true,
        autoplay: true,
        animationData: swipeAnimationData,
        rendererSettings: {
            preserveAspectRatio: 'xMidYMid slice'
        }
    };

    return (
        <div className="splash-intro">

            <div className="smoke-effect">
                <div className="btn-orcamento">
                    <h3>Meu Orçamento</h3>
                </div>
                <div className="swipedown-animation">
                    <Lottie
                        options={swipeAnimationDataConfig}
                        height={100}
                        width={100}
                    />
                </div>
                <IntroSmokeEffect />
            </div>

        </div>

    )
}

export default SplashIntro;